import { FC, SVGProps } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  icon: SVGProps<SVGElement> // viewBoxを指定して渡すことを忘れずに()
  onClick: () => void
  size: 24 | 28 | 32 | 40 | 48
  type?: 'default' | 'filled'
  isDisabled?: boolean
}

export const IconButton: FC<Props> = observer(({ icon, onClick, size, type = 'default', isDisabled = false }) => {
  const className = classNames(
    styles.container,
    {
      [styles.defaultType]: type === 'default',
      [styles.filledType]: type === 'filled',
    },
    {
      [styles.size24]: size === 24,
      [styles.size28]: size === 28,
      [styles.size32]: size === 32,
      [styles.size40]: size === 40,
      [styles.size48]: size === 48,
    },
    {
      [styles.disabled]: isDisabled,
    }
  )

  return (
    <button type='button' className={className} onClick={onClick}>
      {icon}
    </button>
  )
})

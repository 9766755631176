import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { Toast } from '@components-v2/molecules/Toast'
import styles from './index.module.scss'

export const ToastManager = observer(() => {
  const { messages } = useStores()
  return (
    <div className={styles.container}>
      {messages.messages.map((message) => (
        <Toast message={message} key={message.key} />
      ))}
    </div>
  )
})

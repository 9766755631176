import { FC } from 'react'
import LogoSvg from '@/public/images/common/logo_v2.svg'
import styles from './index.module.scss'

export const Logo: FC = () => {
  return (
    <span className={styles.container}>
      <LogoSvg />
    </span>
  )
}

import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  size: 'large' | 'small'
  // auto は theme に合わせて自動で設定
  color?: 'white' | 'primary' | 'auto'
}

export const LoadingIndicator: FC<Props> = ({ size, color = 'auto' }) => {
  const className = classNames(styles.indicator, {
    [styles.large]: size === 'large',
    [styles.small]: size === 'small',
    [styles.white]: color === 'white',
    [styles.primary]: color === 'primary',
  })
  return (
    <div className={styles.container}>
      <div className={className} />
    </div>
  )
}

import { FC } from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { IMessage, MessageState, MessageType } from '@/types'
import { Note } from '@components-v2/molecules/Note'
import styles from './index.module.scss'

type Props = {
  message: IMessage
}

export const Toast: FC<Props> = observer(({ message }) => {
  const { t } = useTranslation('global')

  const onClick = () => {
    message.hide()
  }

  const getNoteType = () => {
    if (message.type === MessageType.Error) {
      return 'danger'
    }

    return 'default'
  }

  return (
    <div
      className={cn(styles.container, {
        [styles.visibleContainer]: message.state === MessageState.Visible,
      })}
    >
      <Note type={getNoteType()} withCloseButton={message.isDismissable} onClickButton={onClick}>
        {message.isTranslated ? message.body : t(message.body)}
      </Note>
    </div>
  )
})
